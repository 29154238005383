<template>
  <div>
    <v-app-bar
      id="home-app-bar"
      app
      color="white"
      elevation="1"
      height="80"
    >
      <router-link to="/">
        <base-img
          :src="require('@/assets/logo-hi5magic.png')"
          class="mr-3"
          contain
          max-width="128"
          max-height="100"
          width="100%"
        />
      </router-link>

      <base-img
        :src="require('@/assets/logo-hi5magic-light.svg')"
        contain
        max-width="150"
        width="100%"
      />
      <v-spacer />

      <div>
        <v-tabs
          class="hidden-sm-and-down"
          optional
        >
          <v-tab
            to="/"
            :ripple="false"
            active-class="text--primary"
            class="font-weight-bold"
            min-width="96"
            text
          >
            Home
          </v-tab>
          <v-tab
            :ripple="false"
            active-class="text--primary"
            class="font-weight-bold"
            min-width="96"
            text
          >
            <v-menu
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <div

                  v-bind="attrs"
                  v-on="on"
                >
                  PRODUCTS
                </div>
              </template>

              <v-list>
                <v-list-item to="/calendar">
                  <v-list-item-title>Calendar</v-list-item-title>
                </v-list-item>
                <v-list-item to="/designs">
                  <v-list-item-title>Design Work</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-tab>
          <v-tab
            v-for="(name, i) in items"
            :key="i"
            :to="{ name }"
            :exact="name === 'Home'"
            :ripple="false"
            active-class="text--primary"
            class="font-weight-bold"
            min-width="96"
            text
          >
            {{ name }}
          </v-tab>
        </v-tabs>
      </div>

      <v-app-bar-nav-icon
        class="hidden-md-and-up"
        @click="drawer = !drawer"
      />
    </v-app-bar>

    <home-drawer
      v-model="drawer"
      :items="items"
    />
  </div>
</template>

<script>
  export default {
    name: 'HomeAppBar',

    components: {
      HomeDrawer: () => import('./Drawer'),
    },

    data: () => ({
      drawer: null,
      items: [
        // 'Home',
        // 'Products',
        'About',
        'Contact',
        // 'Pro',
      ],
    }),
  }
</script>

<style lang="sass">
  #home-app-bar
    .v-tabs-slider
      max-width: 24px
      margin: 0 auto

    .v-tab
      &::before
        display: none
</style>
